import { Component, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { User } from '../data/types/user.interface';
import { CommonModule } from '@angular/common';

interface DialogData {
  title?: string;
  emailOnly?: boolean;
}

@Component({
  selector: 'app-login-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, LoginComponent],
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
})
export class LoginDialogComponent {
  #ref = inject(MatDialogRef<LoginDialogComponent>);
  #data: DialogData = inject(MAT_DIALOG_DATA, {
    optional: true,
  });

  @ViewChild(LoginComponent)
  loginCmp: LoginComponent | null = null;

  title = this.#data?.title ?? 'Login to your account';
  emailOnly = this.#data?.emailOnly ?? false;

  close(user?: User | string) {
    this.#ref.close(user);
  }

  setEmailOnly(emailOnly: boolean) {
    this.emailOnly = emailOnly;
  }
}
