<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <app-login
    [hideActions]="true"
    [emailOnly]="emailOnly"
    emailHint="We'll send you a secure link to login and access your purchase"
    (loggedIn)="close($event)"
    (emailSent)="close($event)"></app-login>
  <p *ngIf="emailOnly">
    Previously signed in with Google or a password? <a (click)="setEmailOnly(false)">Click Here</a>
  </p>
  <p *ngIf="!emailOnly">
    Previously signed in with just your email? <a (click)="setEmailOnly(true)">Click Here</a>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">
    Cancel
  </button>
  <button *ngIf="loginCmp?.tabIndex === 1" mat-raised-button color="primary" [disabled]="!loginCmp?.valid || loginCmp?.signingIn || loginCmp?.registering"
    (click)="loginCmp?.register()">
    {{loginCmp?.registering ? 'Creating Account...' : 'Create Account'}}
  </button>
  <button *ngIf="loginCmp?.tabIndex === 0" mat-raised-button color="primary" [disabled]="!loginCmp?.valid || loginCmp?.signingIn || loginCmp?.registering"
    (click)="loginCmp?.login()">
    {{loginCmp?.signingIn ? 'Signing In...' : 'Sign In'}}
  </button>
</div>