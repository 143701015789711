import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AuthRepository } from '../data/repositories/auth.repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { User } from '../data/types/user.interface';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DOCUMENT } from '@angular/common';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupabaseService } from '../supabase.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { hasUppercase } from '../validators/hasUppercase.validator';
import { hasLowercase } from '../validators/hasLowercase.validator';
import { hasNumber } from '../validators/hasNumber.validator';
import { MatTabsModule } from '@angular/material/tabs';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatPasswordStrengthModule,
    MatTabsModule,
  ],
})
export class LoginComponent {
  #authRepo = inject(AuthRepository);
  #toast = inject(HotToastService);
  #router = inject(Router);
  #sb = inject(SupabaseService);

  @Input()
  controlNav = false;

  @Input()
  emailOnly = false;

  @Input()
  emailHint = '';

  @Input()
  hideActions = false;

  @Input()
  showToasts = true;

  @Input()
  cancelBtnText: string = '';

  @Input()
  cancelBtnIcon: string = '';

  @Output()
  loggedIn = new EventEmitter<User>();

  @Output()
  emailSent = new EventEmitter<string>();

  @Output()
  canceled = new EventEmitter();

  @Output()
  wantsToReset = new EventEmitter();

  @Output()
  resetEmailSent = new EventEmitter();

  @Output()
  viewChange = new EventEmitter<'login' | 'register'>();

  resettingPassword = false;
  wantsToResetPassword = false;

  tabIndex = 0;

  creds = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl('', {
      validators: [
        Validators.minLength(8),
        hasUppercase,
        hasLowercase,
        hasNumber,
      ],
    }),
  });

  googleClientId = environment.googleSignIn.clientId;

  signingIn = false;
  registering = false;

  ngOnInit() {
    if (this.controlNav) {
      if (this.#authRepo.loggedIn) {
        this.#router.navigate(['/']);
      }
    }
    this.#authRepo.loggedIn$
      .pipe(untilDestroyed(this))
      .subscribe((loggedIn) => {
        if (loggedIn) {
          if (this.showToasts) {
            this.#toast.success('Successfully logged in!');
          }
          this.loggedIn.emit(this.#authRepo.user!);
          if (this.controlNav) {
            this.#router.navigate(['/']);
          }
        }
      });
  }

  ngOnChanges() {
    if (!this.emailOnly) {
      // This is only required if not doing email only
      this.creds.get('password')?.addValidators(Validators.required);
    } else {
      this.creds.get('password')?.removeValidators(Validators.required);
    }
  }

  cancel() {
    this.canceled.emit();
  }

  async login() {
    const { email, password } = this.creds.value;
    if (!email) return;
    this.signingIn = true;
    await this.#sb.signInWithEmail(email, password ? password : undefined);
    if (this.emailOnly) {
      this.emailSent.emit(email);
    }
  }

  loginWithGoogle() {
    this.#sb.signInWithGoogle();
  }

  async register() {
    const { email, password } = this.creds.value;
    if (!email || !password) return;
    this.registering = true;
    const user = await this.#sb.signUp(email, password);
    if (!user) {
      this.#toast.error(
        `We're sorry, we've having trouble creating your account, please try again or contact support via the chat widget on the bottom right if the problem persists`
      );
      this.registering = false;
      return;
    }
    this.emailSent.emit(email);
  }

  async startResetPassword() {
    if (this.resettingPassword) return;
    if (!this.email) {
      this.wantsToResetPassword = true;
      this.emailOnly = true;
      this.emailHint = `We'll send you an email here with a link to reset your password`;
      this.hideActions = true;
      this.wantsToReset.emit();
      return;
    }
    this.resettingPassword = true;
    const success = await this.#sb.resetPassword(this.email);
    if (success) {
      // Make this message more obvious
      this.#toast.info(
        'Reset Password email sent! Click the link to update your information'
      );
      this.resettingPassword = false;
      this.resetEmailSent.emit();
    }
  }

  get valid() {
    if (this.emailOnly) {
      return this.creds.get('email')?.valid;
    }
    return this.creds.valid;
  }

  get email() {
    const emailCtrl = this.creds.get('email')!;
    return emailCtrl.valid && emailCtrl.value;
  }

  get email$() {
    return this.creds.valueChanges.pipe(map(() => this.email));
  }
}
