<div [formGroup]="creds">
  <ng-container *ngIf="emailOnly; else fullForm">
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input type="email" matInput formControlName="email" />
      <mat-hint *ngIf="emailHint">
        {{emailHint || "You'll receive an email with a link to verify your account"}}
      </mat-hint>
    </mat-form-field>
    <div class="actions">
      <div class="flex-spacer"></div>
      <button
        mat-button
        color="primary"
        *ngIf="wantsToResetPassword"
        [disabled]="!email || resettingPassword"
        (click)="startResetPassword()">
        {{resettingPassword ? 'Sending email...' : 'Reset Password'}}
      </button>
    </div>
  </ng-container>
  <ng-template #fullForm>
    <div
      class="social">
      <p>Get in Fastest:</p>
      <button
        mat-button
        color="primary"
        (click)="loginWithGoogle()">
        Sign In With Google
      </button>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group [(selectedIndex)]="tabIndex">
      <mat-tab label="Login">
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input type="email" matInput formControlName="email" />
        <mat-hint *ngIf="emailHint">
            {{emailHint || "You'll receive an email with a link to verify your account"}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" />
          <mat-hint class="forgot-password">
            Forgot Password?
            <a (click)="startResetPassword()" [class.disabled]="resettingPassword">
              {{resettingPassword ? 'Sending email...' : 'Reset Password'}}
            </a>
          </mat-hint>
        </mat-form-field>
      </mat-tab>
      <mat-tab label="Create Account">
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input type="email" matInput formControlName="email" />
          <mat-hint *ngIf="emailOnly || emailHint">
            {{emailHint || "You'll receive an email with a link to verify your account"}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" #password />
        </mat-form-field>
        <mat-password-strength
          #passwordStrength
          [password]="password.value"
          [enableSpecialCharRule]="false"></mat-password-strength>
        <mat-password-strength-info
          *ngIf="creds.get('password')?.dirty"
          [passwordComponent]="passwordStrength"></mat-password-strength-info>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
<div *ngIf="!hideActions" class="actions" [class.hasCancel]="cancelBtnText">
  <ng-container
    *ngIf="cancelBtnText">
    <button
      mat-button
      (click)="cancel()">
      <mat-icon *ngIf="cancelBtnIcon">{{cancelBtnIcon}}</mat-icon>
      {{cancelBtnText}}
    </button>
    <div class="flex-spacer"></div>
  </ng-container>
  <button
    *ngIf="tabIndex === 1"
    mat-raised-button
    color="primary"
    [disabled]="creds.invalid || signingIn || registering"
    (click)="register()">
    {{registering ? 'Creating Account...' : 'Create Account'}}
  </button>
  <button
    *ngIf="tabIndex === 0"
    mat-raised-button
    color="primary"
    [disabled]="creds.invalid || signingIn || registering"
    (click)="login()">
    {{signingIn ? 'Signing In...' : 'Sign In'}}
  </button>
</div>