import { ValidatorFn } from '@angular/forms';

const regex = /[0-9]/;

export const hasNumber: ValidatorFn = (ctrl) => {
  if (regex.test(ctrl.value)) return null;
  return {
    hasNumber: { value: ctrl.value },
  };
};
