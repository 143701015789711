import { ValidatorFn } from '@angular/forms';

const regex = /[A-Z]/;

export const hasUppercase: ValidatorFn = (ctrl) => {
  if (regex.test(ctrl.value)) return null;
  return {
    hasUppercase: { value: ctrl.value },
  };
};
